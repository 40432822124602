<template>
  <el-select
    :value="selected"
    multiple
    collapse-tags
    @input="dispatch"
    :placeholder="placeholder || $t('message.color')"
    :size="size"
    class="w-100"
    filterable 
    clearable
  >
    <el-option
      v-for="(color, index) in colors"
      :key="'color-' + index"
      :label="color.name"
      :value="color.id"
      :style="'color:white; background:' + color.color"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    color_ids: {
      default: Array,
    },
  },
  watch: {
    color_ids: {
      handler: function () {
        this.selected = this.color_ids;
      },
      immediate: true,
    },
  },
  data() {
    return {
        selected: [],
    };
  },

  mounted() {
    this.updateInventory();
  },
  computed: {
    ...mapGetters({
      colors: "colors/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "colors/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>